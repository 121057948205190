import { useHistory } from 'react-router-dom';

import React from 'react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import routes from 'core/routes';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition, FilterType } from 'components/Grid/types';

import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import { ListRendererType } from 'components/Grid/renderers/GridListRenderer/types';
import { listRenderer } from 'components/Grid/renderers/GridListRenderer';
import GridChipRenderer from 'components/Grid/renderers/GridChipRenderer';

import Search from 'components/Grid/operators/Search';
import API from 'utils/api/api';

import { formatDateForDisplay } from 'utils/formatters/time';

import { StyledCreated, Container } from '../UsageReportTable/styles';

const basicColumns = ([
  {
    field: 'phoneNumber',
    headerName: 'Phone',
    nativeFilter: true,
  },
  {
    field: 'tags',
    headerName: 'User tags',
    filterOperators: Search(API.searchUserTags, API.userTagsDetails, 'name'),
    renderCell: listRenderer((GridChipRenderer as unknown) as ListRendererType, 3),
    nativeFilter: 'user_tags',
    valueGetter: ({ row }: GridValueGetterParams) => row.user.tags,
  },
  {
    field: 'user',
    headerName: 'User',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'users',
  },
  {
    field: 'lastPoll',
    filterOperators: defaultOperators.date_time(),
    headerName: 'Last online',
    renderCell: ({ value }: { value: string }) =>
      value ? <StyledCreated>{formatDateForDisplay(value)}</StyledCreated> : <EmptyValue>-</EmptyValue>,
    nativeFilter: true,
    valueFormatter: defaultFormatters.date,
  },
] as unknown) as ColumnDefinition[];

const DeviceList: React.FunctionComponent<{
  onError: (error: VatixError) => void;
  filters: FilterType[];
  onRefreshCallback: (callback: () => void) => void;
}> = ({ onError, filters, onRefreshCallback }): React.ReactElement => {
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    history.push(reverse(routes.dashboard.alarms.devices.details, { deviceId: task.row.uuid }));
  };

  return (
    <Container>
      <Grid
        temporaryFilters={filters}
        onRefreshCallback={onRefreshCallback}
        basicColumns={basicColumns}
        entity="device"
        onError={onError}
        onRowClick={onRowClick}
        dataURL="/api/reports/usage/table/?type=mobile"
        disableColumnSelector
      />
    </Container>
  );
};

export default DeviceList;
