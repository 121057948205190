import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { Button } from 'vatix-ui/lib/components/Button';

import AddIcon from '@mui/icons-material/Add';

import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { ColumnDefinition } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import Grid from 'components/Grid';
import routes from 'core/routes';

import { EntitiesStores } from 'components/Entities/types';

import AbstractWrapper from './AbstractWrapper';
import { defaultProps } from './types';

const IncidentColumns = ([
  {
    field: 'subject',
    headerName: 'Name',
    nativeFilter: true,
  },
  {
    field: 'Type',
    customColumn: true,
  },
  {
    field: 'Status',
    customColumn: true,
  },
] as unknown) as ColumnDefinition[];

const IncidentRelatedToEntities: React.FunctionComponent<{
  onError: (error: VatixError) => void;
  store: EntitiesStores;
}> = ({ onError, store }) => {
  const {
    session: { user },
    incidentDetails: { setExtraParamsForCreatingIncident },
    routing,
  } = useStore();

  const { details } = store;
  const type = store.entityType;

  const history = useHistory();

  const handleCreateIncident = (): void => {
    if (!details) return;
    setExtraParamsForCreatingIncident({ [type]: details.uuid });
    history.push(reverse(routes.dashboard.incidents.report));
  };

  const onRowClick: GridEventListener<'rowClick'> = (incident: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.incidents.details, { incidentId: incident.row.uuid }));
  };

  return (
    user?.licenses.protectorIncidents && (
      <AbstractWrapper
        headerTitle="Incidents"
        headerAction={
          <Button variant="text" size="medium" startIcon={<AddIcon />} onClick={handleCreateIncident}>
            New Incident
          </Button>
        }
        gridElement={
          <Grid
            onError={onError}
            onRowClick={onRowClick}
            basicColumns={IncidentColumns}
            dataURL={`/api/entities/${type}/${details?.uuid}/incidents/`}
            fieldsURL="/api/incidents/fields/"
            {...defaultProps}
          />
        }
        footerAction={
          <Button variant="text" size="medium" disabled>
            View all...
          </Button>
        }
      />
    )
  );
};

export default IncidentRelatedToEntities;
