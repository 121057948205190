import styled from 'styled-components';

export const RoundBox = styled.div<{ $roundColor: string; $border?: string; $zIndex?: number }>`
  cursor: default;
  width: 31px;
  height: 31px;
  border-radius: 99px;
  border: ${(props) => props.$border};
  background-color: ${(props) => props.$roundColor};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.$zIndex || 1};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
`;

export const BreadCrumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  width: 286px;
  padding: 16px;
`;

export const BreadCrumbsContainerHeader = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const BreadCrumbsContainerText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 4px 0;
`;
