/* eslint-disable max-len */
import * as React from 'react';

export function UploadIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="41" viewBox="0 0 42 41" fill="none">
      <path
        d="M27.8327 27.3333L20.9993 20.5L14.166 27.3333"
        stroke="#9E9E9E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21 20.5V35.875" stroke="#9E9E9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M35.3329 31.4162C36.9991 30.5078 38.3154 29.0705 39.0739 27.3309C39.8325 25.5914 39.9902 23.6488 39.5221 21.8097C39.054 19.9706 37.9868 18.3398 36.4889 17.1746C34.991 16.0094 33.1477 15.3762 31.25 15.375H29.0975C28.5804 13.3749 27.6166 11.5181 26.2787 9.94415C24.9407 8.37019 23.2633 7.12003 21.3726 6.28766C19.4819 5.45528 17.4271 5.06236 15.3627 5.13842C13.2983 5.21448 11.278 5.75755 9.45367 6.7268C7.62936 7.69605 6.04851 9.06625 4.82997 10.7344C3.61144 12.4026 2.78692 14.3252 2.41842 16.3579C2.04991 18.3906 2.14701 20.4804 2.7024 22.4701C3.25779 24.4599 4.25703 26.2978 5.62499 27.8458"
        stroke="#9E9E9E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8327 27.3333L20.9993 20.5L14.166 27.3333"
        stroke="#9E9E9E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
