import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'vatix-ui/lib/components/Button';

import { Formik, Form } from 'formik';

import { CircularProgress, MenuItem, Tooltip } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { AxiosError } from 'axios';

import CustomModal from 'components/CustomModal';
import { StyledSelect, StyledTextField } from 'components/Input/styles';

import { DisplayProtectorType, EntityFieldType, ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { ButtonsWrapper, FieldHeader, StyledModalActions, WarningContainer } from './styles';

import EditMultipleAnswers from '../DropdownOptions';
import {
  EntityFieldTypeWithoutUUID,
  EntityFiledValidationSchema,
  getBorderStyle,
  modifyInputValue,
  possibleProtectorTypes,
} from './types';

const SubmitButton: React.FC<{ isSubmitting: boolean; isResetting: boolean; editView: boolean }> = ({
  isResetting,
  isSubmitting,
  editView,
}) => (
  <Button
    id="create-field-button"
    variant="contained"
    size="large"
    type="submit"
    disabled={isSubmitting || isResetting}
    style={{ marginLeft: '8px' }}
  >
    {isSubmitting && <CircularProgress color="inherit" size="16px" style={{ marginRight: '8px' }} />}
    {editView ? 'Save' : 'Create'}
  </Button>
);

const CancelButton: React.FC<{ handleClose: () => void; editView: boolean }> = ({ handleClose, editView }) => (
  <Button
    id="cancel-button"
    variant={editView ? 'outlined' : 'text'}
    size="large"
    onClick={handleClose}
    data-testid="cancelButton"
  >
    Cancel
  </Button>
);

const CreateEditFieldModal: React.FunctionComponent<{
  entityType: string;
  editedValues: EntityFieldType;
  onClose: (refresh: boolean) => void;
}> = ({ entityType, editedValues, onClose }): React.ReactElement => {
  const {
    entityFields: { editField, createField },
    notification,
  } = useStore();
  const editView = editedValues.uuid !== '';

  const [isResetting, setIsResetting] = React.useState(false);
  const [multipleAnswers, setMultipleAnswers] = React.useState(
    editedValues.protectorType === ProtectorType.MultiChoice
  );
  const [options, setOptions] = React.useState<string[]>(editedValues.properties?.enum || []);

  const updateOptions = (newOptions: string[]): void => {
    setOptions(newOptions);
  };

  const isDropdownOption = (protectorType: string): boolean =>
    protectorType === ProtectorType.SingleChoice || protectorType === ProtectorType.MultiChoice;

  const prepareData = (values: EntityFieldTypeWithoutUUID): EntityFieldTypeWithoutUUID => {
    if (values.protectorType === ProtectorType.SingleChoice && multipleAnswers) {
      values.protectorType = ProtectorType.MultiChoice;
    }
    if (values.protectorType === ProtectorType.MultiChoice || values.protectorType === ProtectorType.SingleChoice) {
      values.properties = {
        enum: options,
      };
    }

    return values;
  };

  const handleCreate = async (values: EntityFieldTypeWithoutUUID, resetForm?: () => void): Promise<void> => {
    try {
      const dataToSubmit = prepareData(values);
      await createField(entityType, dataToSubmit);

      if (resetForm) {
        resetForm();
      } else {
        onClose(true);
      }
      notification.enqueueSuccessSnackbar('Field created successfully');
    } catch (e) {
      const error = e as AxiosError;
      notification.enqueueErrorSnackbar(error.response?.data[0] || 'Failed to create field');
    } finally {
      setIsResetting(false);
    }
  };

  const handleEdit = async (values: EntityFieldTypeWithoutUUID, editedVal: EntityFieldType): Promise<void> => {
    try {
      const dataToSubmit = prepareData(values);
      await editField(entityType, editedVal.uuid, dataToSubmit);
      notification.enqueueSuccessSnackbar('Field edited successfully');
      onClose(true);
    } catch (e) {
      notification.enqueueErrorSnackbar('Failed to edit field');
    } finally {
      setIsResetting(false);
    }
  };

  const handleSubmit = async (values: EntityFieldTypeWithoutUUID, resetForm?: () => void): Promise<void> => {
    if (resetForm) setIsResetting(true);
    if (editView) {
      await handleEdit(values, editedValues);
    } else {
      await handleCreate(values, resetForm);
    }
  };

  // @ts-expect-error
  const initialValues: EntityFieldTypeWithoutUUID = editView
    ? { ...editedValues }
    : {
        protectorType: '',
        name: '',
        key: '',
        description: '',
      };

  return (
    <CustomModal openModal onClose={() => onClose(false)} modalTitle={editView ? 'Edit Field' : 'Create Field'}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={EntityFiledValidationSchema}
      >
        {({ values, handleChange, setFieldValue, isSubmitting, errors, touched, resetForm }) => (
          <Form>
            {/* if in edit view and type is dropdown display warning message */}
            {editView && isDropdownOption(values.protectorType) ? (
              <WarningContainer>
                <WarningAmberIcon
                  style={{
                    color: 'rgba(237, 108, 2, 1)',
                    width: '24px',
                    height: '24px',
                  }}
                />
                <p>Changing the dropdown options may result in losing existing values</p>
              </WarningContainer>
            ) : null}
            <FieldHeader>Field Type</FieldHeader>
            <StyledSelect
              displayEmpty
              fullWidth
              id="protectorType"
              name="protectorType"
              placeholder="Choose an Option"
              value={
                values.protectorType === ProtectorType.MultiChoice ? ProtectorType.SingleChoice : values.protectorType
              }
              onChange={handleChange}
              disabled={editView}
              error={Boolean(errors.protectorType && touched.protectorType)}
              $withoutBorder
              style={{
                color: values.protectorType ? 'rgb(0,0,0)' : 'rgba(0, 0, 0, 0.7)',
                border: getBorderStyle(editView, errors, touched),
              }}
            >
              <MenuItem value="" disabled>
                Choose an Option
              </MenuItem>
              {possibleProtectorTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type === ProtectorType.SingleChoice ? 'Dropdown' : DisplayProtectorType[type]}
                </MenuItem>
              ))}
            </StyledSelect>
            {isDropdownOption(values.protectorType) ? (
              <EditMultipleAnswers
                options={options}
                setOptions={updateOptions}
                multipleAnswers={multipleAnswers}
                setMultipleAnswers={setMultipleAnswers}
              />
            ) : null}

            <FieldHeader>Field Label</FieldHeader>
            <StyledTextField
              fullWidth
              id="name"
              name="name"
              placeholder="Field Label"
              value={values.name}
              onChange={(event) => {
                handleChange(event);
                if (editView) return;
                const keyVal = modifyInputValue(entityType, event.target.value);
                setFieldValue('key', keyVal);
              }}
              error={Boolean(errors.name && touched.name)}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FieldHeader>API Name</FieldHeader>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: '#EAEBED',
                      color: '#000',
                      margin: 0,
                      padding: '4px 8px',
                      fontSize: '12px',
                      fontWeight: 400,
                    },
                  },
                }}
                title="Used to read from and write to the field from the API"
                placement="right"
              >
                <InfoIcon fontSize="small" style={{ margin: '16px 0 8px 6px', color: 'rgba(0, 0, 0, 0.54)' }} />
              </Tooltip>
            </div>
            <StyledTextField
              fullWidth
              id="key"
              name="key"
              placeholder="API Name"
              value={values.key}
              onChange={handleChange}
              disabled={editView}
              error={Boolean(errors.key && touched.key)}
            />
            <FieldHeader>Description</FieldHeader>
            <StyledTextField
              fullWidth
              id="description"
              name="description"
              placeholder="Description"
              value={values.description}
              onChange={handleChange}
            />
            {editView ? (
              <ButtonsWrapper>
                <CancelButton handleClose={() => onClose(false)} editView={editView} />
                <SubmitButton isSubmitting={isSubmitting} isResetting={isResetting} editView={editView} />
              </ButtonsWrapper>
            ) : (
              <StyledModalActions>
                <CancelButton handleClose={() => onClose(false)} editView={editView} />
                <div>
                  <Button
                    size="large"
                    id="reset-button"
                    variant="outlined"
                    onClick={() => {
                      handleSubmit(values, resetForm);
                    }}
                    disabled={isSubmitting || isResetting}
                  >
                    Create and Add Another
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} isResetting={isResetting} editView={editView} />
                </div>
              </StyledModalActions>
            )}
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default observer(CreateEditFieldModal);
