import React from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Droppable, DropResult, DragDropContext } from 'react-beautiful-dnd';

import { observer } from 'mobx-react';

import { TextInput } from 'vatix-ui/lib/components/TextInput';

import { Button } from 'vatix-ui/lib/components/Button';

import { useStore } from 'utils/hooks/store';

import DroppableSections from '../DroppableSections';

import {
  AddText,
  BaseLayout,
  Layout,
  NonQuestionRow,
  QuestionsLeftSide,
  TemplateNameLabel,
  TitleRightSide,
} from './styles';

const QuestionsEditor: React.FunctionComponent = (): React.ReactElement => {
  const { templateDetails } = useStore();

  const sectionsOrder = templateDetails.details?.content.order || [];

  const onDragEnd = (result: DropResult): void => {
    const { destination, source, type } = result;

    if (!destination) {
      return;
    }

    if (type === 'SECTIONS') {
      // reorder sections
      templateDetails.details?.reorderSections(destination.index, source.index);
    } else if (destination.droppableId !== source.droppableId) {
      // reorder question between sections
      const sourceSection = source.droppableId.charAt(source.droppableId.length - 1);
      const destinationSection = destination.droppableId.charAt(destination.droppableId.length - 1);
      templateDetails.details?.reorderBetweenSections(
        source.index,
        destination.index,
        sourceSection,
        destinationSection
      );
    } else if (destination.index !== source.index) {
      // reorder question in section
      const sectionId = source.droppableId.charAt(source.droppableId.length - 1);
      templateDetails.details?.reorder(source.index, destination.index, sectionId);
    }
  };

  return (
    <BaseLayout>
      <Layout>
        <NonQuestionRow>
          <TemplateNameLabel>Template Name</TemplateNameLabel>
        </NonQuestionRow>
        <NonQuestionRow>
          <TextInput
            onChange={(event) => templateDetails.details?.updateTemplateName(event.target.value)}
            placeholder="Add name for your form template here..."
            value={templateDetails.details?.name}
            id="template-name-text-input"
          />
        </NonQuestionRow>

        <NonQuestionRow>
          <QuestionsLeftSide>
            <TemplateNameLabel>Questions</TemplateNameLabel>
          </QuestionsLeftSide>
          <TitleRightSide>
            <TemplateNameLabel>Response Type</TemplateNameLabel>
          </TitleRightSide>
        </NonQuestionRow>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="SECTIONS">
            {(provided) => (
              <div ref={provided.innerRef}>
                {sectionsOrder.map((section, index) => (
                  <DroppableSections section={section} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <NonQuestionRow>
          <Button style={{ margin: '24px 0' }} onClick={() => templateDetails.details?.addSection()}>
            <AddCircleIcon color="primary" />
            <AddText>Add New Section</AddText>
          </Button>
        </NonQuestionRow>
      </Layout>
    </BaseLayout>
  );
};

export default observer(QuestionsEditor);
