import React from 'react';

import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { Button } from 'vatix-ui/lib/components/Button';

import { Menu, MenuItem } from '@mui/material';

import api from 'utils/api';

import { useStore } from 'utils/hooks/store';
import { ColumnDefinition } from 'components/Grid/types';
import { OrderingParams, QueryParams } from 'utils/api/types';

const exportToCSV = async (
  url: string,
  columnsState: { [key: string]: boolean },
  columnsDefinition: ColumnDefinition[]
): Promise<{ baseUrl: string; params: OrderingParams | QueryParams | undefined }> => {
  let baseUrl = url;
  let params: { [key: string]: string } = {};
  const indexOfQuery = url.indexOf('?');
  if (indexOfQuery === -1) {
    baseUrl = url;
    params = {};
  } else {
    const [base, rest] = url.split('?');
    baseUrl = base;
    rest.split('&').forEach((param) => {
      const [key, value] = param.split('=');
      if (key === 'custom') {
        // custom params are encoded in the URL from Grid component
        // so we have to decode them before sending them to the API
        params = { ...params, custom: decodeURIComponent(value) };
      } else {
        params = { ...params, [key]: value };
      }
    });
  }

  const nativeFields = columnsDefinition
    .filter((col: ColumnDefinition) => !col.customColumn && columnsState[col.field] && col.field !== 'actions')
    .map((col: ColumnDefinition) => col.field);
  const customFields = columnsDefinition
    .filter((col: ColumnDefinition) => col.customColumn && columnsState[col.field])
    .map((col: ColumnDefinition) => col.field);

  params = {
    ...params,
    field: nativeFields.join(','),
    custom_field: customFields.map(encodeURIComponent).join(','),
  };

  return {
    baseUrl,
    params,
  };
};

const ToolbarExport: React.FunctionComponent<{
  url: string;
  columnsState: { [key: string]: boolean };
  columnsDefinition: ColumnDefinition[];
}> = ({ url, columnsState, columnsDefinition }) => {
  const { notification } = useStore();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleExport = async (): Promise<void> => {
    const { baseUrl, params } = await exportToCSV(url, columnsState, columnsDefinition);
    try {
      await api.downloadAllRowsToCSV(`${baseUrl}csv/`, params)();
    } catch (e) {
      notification.enqueueErrorSnackbar('Failed to download CSV');
    }
    handleClose();
  };

  return (
    <>
      <Button
        id="grid-export-button"
        aria-controls={open ? 'export-grid-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ padding: '4px 5px' }}
      >
        <SaveAltIcon style={{ marginRight: '8px' }} htmlColor="rgb(75, 160, 253)" />
        <p style={{ color: 'rgb(75, 160, 253)', margin: 0 }}>Export</p>
      </Button>
      <Menu id="export-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleExport}>Download current page as CSV</MenuItem>
      </Menu>
    </>
  );
};

export default ToolbarExport;
