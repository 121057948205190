import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from 'utils/hooks/store';

import AutoplayAudio from 'components/NotificationBar/AutoplayAudio';
import Audio from 'components/Audio';

import { ActivityMapTab } from '../../constants';
import { Container, Line, OptionContainer, OptionLabel, OptionLine, Wrapper } from './styles';

const ActivityTabs: React.FC = () => {
  const { activityMap, session, pendingRedAlerts, ui } = useStore();
  const numAlerts = pendingRedAlerts.alerts.length;

  const handleClick = (event: React.ChangeEvent<{}>, tab: ActivityMapTab): void => {
    activityMap.changeTab(event, tab);
  };

  return (
    <Wrapper>
      <Container>
        {session.user?.licenses.protectorAlarms && (
          <OptionContainer onClick={(e) => handleClick(e, ActivityMapTab.People)}>
            <OptionLine>
              <OptionLabel active={activityMap.selectedTab === ActivityMapTab.People}>People</OptionLabel>
            </OptionLine>
            {activityMap.selectedTab === ActivityMapTab.People && <Line />}
          </OptionContainer>
        )}
        {session.user?.licenses.protectorAlarms && (
          <OptionContainer onClick={(e) => handleClick(e, ActivityMapTab.Alarms)}>
            <OptionLine>
              <OptionLabel active={activityMap.selectedTab === ActivityMapTab.Alarms}>Alarms</OptionLabel>
            </OptionLine>
            {activityMap.selectedTab === ActivityMapTab.Alarms && <Line />}
          </OptionContainer>
        )}
      </Container>
      <AutoplayAudio />
      {ui.canAutoplayAudio && (
        <Audio play={!!numAlerts} src={process.env.REACT_APP_ALARM_NOTIFICATION as string} loop />
      )}
    </Wrapper>
  );
};

export default observer(ActivityTabs);
