import styled from 'styled-components';

export const ContainerAddFilters = styled.div`
  margin-top: 12px;
`;

export const FilterMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  gap: 10px;

  margin-top: 45px;

  background-color: rgb(255, 255, 255);
  min-width: 300px;
  max-height: 450px;
  display: flex;

  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

export const NoFilters = styled.p`
  font-size: 14px;
  margin-top: 0;
`;
