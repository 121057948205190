import React from 'react';
import { OverlayView } from '@react-google-maps/api';

import { getPixelPositionOffset } from 'vatix-ui/lib/components/Map/Marker/utils';

import { HoveredMarkerProps } from './types';
import { RoundBox } from './styles';

const HoveredMarker: React.FunctionComponent<HoveredMarkerProps> = ({
  lat,
  lng,
  icons,
  onClick,
  onMouseOut,
  onMouseOver,
  background,
  border,
  zIndex,
}): React.ReactElement => {
  const position = {
    lat,
    lng,
  };

  const [isHovered, setIsHovered] = React.useState(false);
  const markerRef = React.useRef(null);

  React.useEffect(() => {
    if (markerRef && markerRef.current) {
      // @ts-ignore
      markerRef.current.parentElement.style.zIndex = zIndex;
      if (isHovered) {
        // @ts-ignore
        markerRef.current.parentElement.style.zIndex = 200;
      }
    }
  });

  return (
    <OverlayView
      getPixelPositionOffset={getPixelPositionOffset}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}
    >
      <RoundBox
        ref={markerRef}
        roundColor={background || 'black'}
        borderColor={border}
        hovered={isHovered}
        // @ts-ignore
        onClick={onClick}
        onMouseOver={(e) => {
          onMouseOver && onMouseOver((e as unknown) as google.maps.MapMouseEvent);
          setIsHovered(true);
        }}
        onMouseOut={(e) => {
          onMouseOut && onMouseOut((e as unknown) as google.maps.MapMouseEvent);
          setIsHovered(false);
        }}
      >
        {icons}
      </RoundBox>
    </OverlayView>
  );
};

export default HoveredMarker;
