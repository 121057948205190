import React from 'react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { Button } from 'vatix-ui/lib/components/Button';

import { GridValueGetterParams, GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import { ColumnDefinition } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import Grid, { defaultFormatters, defaultRenderers } from 'components/Grid';

import { formatFullDateTime } from 'utils/formatters/time';

import routes from 'core/routes';
import { EntitiesStores } from 'components/Entities/types';

import AbstractWrapper from './AbstractWrapper';
import { defaultProps } from './types';

const AuditsColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    nativeFilter: true,
  },
  {
    field: 'reporter',
    headerName: 'Reporter',
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'reporters',
  },
  {
    field: 'submitted',
    headerName: 'Submitted',
    renderCell: ({ value }: { value: string | null }) => (value !== null ? formatFullDateTime(value) : 'Not submitted'),
    nativeFilter: 'submitted',
  },
] as unknown) as ColumnDefinition[];

const AuditsRelatedToSites: React.FunctionComponent<{
  onError: (error: VatixError) => void;
  store: EntitiesStores;
}> = ({ store }) => {
  const {
    session: { user },
  } = useStore();

  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    history.push(reverse(routes.dashboard.audits.forms.details, { formId: form.row.uuid }));
  };

  return (
    user?.licenses.workflows && (
      <AbstractWrapper
        headerTitle="Audits"
        gridElement={
          <Grid
            onRowClick={onRowClick}
            basicColumns={AuditsColumns}
            // TODO: replace query with correct one
            dataURL={`${process.env.REACT_APP_WORKFLOWS_API_URL}inspections/?site_uuid=${store.details?.uuid}`}
            {...defaultProps}
          />
        }
        footerAction={
          <Button variant="text" size="medium" disabled>
            View all...
          </Button>
        }
      />
    )
  );
};

export default AuditsRelatedToSites;
