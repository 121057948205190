/* eslint-disable array-callback-return */
import { action, observable, makeObservable, runInAction, IObservableArray } from 'mobx';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import _ from 'lodash';

import { TemplateDetailsResponse } from 'utils/api/types';

import { KindOfQuestionTypes, MultipleAnswerProperties, MultipleAnswerQuestionType, TemplateContent } from './types';
import RootStore from '../Root';

interface FormOption {
  options: string[];
  scoring: string[] | null;
}
const arrayEquals = (a: string[], b: string[]): boolean =>
  a.length === b.length && a.every((item, idx) => item === b[idx]);

export default class DetailsTemplate {
  @observable uuid: string;

  @observable name: string;

  @observable content: TemplateContent;

  @observable isActive: boolean;

  @observable lastUpdate: string;

  @observable creator: UuidableName;

  @observable formOptionsObject: IObservableArray<FormOption>;

  @observable usedOptions: Array<FormOption>;

  store: RootStore;

  constructor(data: TemplateDetailsResponse, rootStore: RootStore) {
    this.store = rootStore;
    this.uuid = data.uuid;
    this.name = data.name;
    // @ts-ignore
    this.content = data.content;
    this.isActive = data.isActive;
    this.lastUpdate = data.lastUpdate;
    this.creator = data.creator;
    this.usedOptions = this.getUsedOptions();
    this.formOptionsObject = observable.array(this.usedOptions);
    makeObservable(this);
  }

  sectionIdGenerator(order: string[]): string {
    return String(Math.max(...order.map(Number)) + 1);
  }

  isQuestionMandatory(questionId: string, sectionId: string): boolean {
    return this.content.properties[sectionId].mandatory.find((key) => key === questionId) !== undefined;
  }

  private getNewQuestionId(order: string[]): string {
    return order.length > 0
      ? String(Math.max(...order.map((element) => Number(element.substring(element.lastIndexOf(':') + 1)))) + 1)
      : String(order.length);
  }

  @action.bound
  getUsedOptions(): FormOption[] {
    const availableOptions: FormOption[] = [{ options: ['Yes', 'No'], scoring: null }];
    const question = this.content.properties;

    Object.keys(question).map((key) => {
      const { properties } = question[key];
      Object.keys(properties).map((questionKey) => {
        if (properties[questionKey].workflowsType === 'multi') {
          const multipleAnswerQuestion = properties[questionKey] as MultipleAnswerQuestionType;

          const includes = availableOptions.some(
            (option) =>
              _.isEqual(multipleAnswerQuestion.items.enum, option.options) &&
              _.isEqual(multipleAnswerQuestion.scoring, option.scoring)
          );

          if (!includes) {
            availableOptions.push({
              options: multipleAnswerQuestion.items.enum,
              scoring: multipleAnswerQuestion.scoring || [],
            });
          }
        }
      });
    });

    return availableOptions;
  }

  @action.bound
  editOption(options: string[], choice: string[], scoring: string[] | null): void {
    const newOption = {
      options,
      scoring,
    };
    const oldOption = {
      options: choice,
      scoring,
    };
    if (choice.length) {
      const index = this.formOptionsObject.findIndex((a) => _.isEqual(oldOption, a));
      if (index !== -1) {
        this.formOptionsObject[index] = newOption;
        return;
      }
    }
    this.addNewOption(options, scoring);
  }

  @action.bound
  addNewOption(options: string[], scoring: string[] | null): void {
    if (scoring && scoring.length !== options.length) {
      return;
    }
    const newOption = {
      options,
      scoring,
    };
    if (!this.formOptionsObject.some((formOption) => _.isEqual(formOption, newOption))) {
      this.formOptionsObject.push(newOption);
    }
  }

  @action.bound
  updateTemplateName(newName: string): void {
    this.name = newName;
  }

  @action.bound
  changeSectionName(sectionId: string, value: string): void {
    this.content.properties[sectionId].description = value;
  }

  @action.bound
  deleteOption(options: string[]): boolean {
    const index = this.getUsedOptions().findIndex((a) => options.every((v, i) => v === a.options[i]));

    if (index === -1) {
      const x = this.formOptionsObject.filter((item) => !arrayEquals(item.options, options));
      runInAction(() => {
        this.formOptionsObject.replace(x);
      });
      return true;
    }
    this.store.notification.enqueueErrorSnackbar('Option is used in template.');
    return false;
  }

  haveDescription(): boolean {
    let val = true;

    Object.keys(this.content.properties).map((key) => {
      const { properties } = this.content.properties[key];
      if (key !== '0' && this.content.properties[key].description === '') {
        val = false;
      }
      Object.keys(properties).map((questionKey) => {
        if (properties[questionKey].description === '') {
          val = false;
        }
      });
    });

    return val;
  }

  @action.bound
  duplicateQuestion(questionId: string, sectionId: string): void {
    const sectionProperties = this.content.properties[sectionId];

    const newQuestionId = this.getNewQuestionId(sectionProperties.order);
    const indexOf = sectionProperties.order.indexOf(questionId);
    const isMandatory = this.isQuestionMandatory(questionId, sectionId);
    const id = `${sectionId}::${newQuestionId}`;

    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [sectionId]: {
          ...this.content.properties[sectionId],
          order: [...this.content.properties[sectionId].order, id],
          mandatory: [...this.content.properties[sectionId].mandatory],
          properties: {
            ...this.content.properties[sectionId].properties,
            [id]: this.content.properties[sectionId].properties[questionId],
          },
        },
      },
    };

    isMandatory && this.setQuestionAsMandatory(id, sectionId);

    this.reorder(this.content.properties[sectionId].order.indexOf(id), indexOf, sectionId);
  }

  @action.bound
  duplicateSections(sectionId: string): void {
    const newSectionId = this.sectionIdGenerator(this.content.order);

    const { properties, order, mandatory } = this.content.properties[sectionId];

    const sectionProp = {};
    Object.keys(properties).map((questionKey) => {
      Object.assign(sectionProp, { [newSectionId + questionKey.substring(1)]: properties[questionKey] });
    });

    this.content = {
      ...this.content,
      order: [...this.content.order, newSectionId],
      properties: {
        ...this.content.properties,
        [newSectionId]: {
          ...this.content.properties[sectionId],
          order: order.map((o) => newSectionId + o.substring(1)),
          mandatory: mandatory.map((m) => newSectionId + m.substring(1)),
          properties: sectionProp,
        },
      },
    };

    this.reorderSections(this.content.order.indexOf(newSectionId), this.content.order.indexOf(sectionId) + 1);
  }

  @action.bound
  addQuestion(sectionId?: string): void {
    const secId = sectionId || '0';
    const newQuestionId = this.getNewQuestionId(this.content.properties[secId].order);

    const id = `${secId}::${newQuestionId}`;

    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [secId]: {
          ...this.content.properties[secId],
          order: [...this.content.properties[secId].order, id],
          properties: {
            ...this.content.properties[secId].properties,
            [id]: {
              description: '',
              type: 'boolean',
              workflowsType: 'boolean',
            },
          },
        },
      },
    };
  }

  @action.bound
  addSection(): void {
    const newSectionId = this.sectionIdGenerator(this.content.order);

    this.content = {
      ...this.content,
      order: [...this.content.order, newSectionId],
      properties: {
        ...this.content.properties,
        [newSectionId]: {
          type: 'object',
          description: '',
          workflowsType: 'section',
          order: [],
          mandatory: [],
          properties: {},
        },
      },
    };
  }

  @action.bound
  changeQuestionType(
    questionId: string,
    type: KindOfQuestionTypes,
    extraProps?: MultipleAnswerProperties,
    extraDefinition?: object,
    sectionId?: string
  ): void {
    const secId = sectionId || '0';
    const types: { [key: string]: KindOfQuestionTypes } = {
      location: 'object',
      signature: 'string',
      multi: 'array',
      date: 'string',
    };
    const newType = types[type] || type;

    const prop =
      type === 'location'
        ? {
            lng: '',
            lat: '',
            address: '',
          }
        : extraProps;

    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [secId]: {
          ...this.content.properties[secId],
          properties: {
            ...this.content.properties[secId].properties,
            [questionId]: {
              ...this.content.properties[secId].properties[questionId],
              type: newType,
              properties: prop,
              workflowsType: type,
              ...extraDefinition,
            },
          },
        },
      },
    };
  }

  @action.bound
  removeQuestion(questionId: string, sectionId: string): void {
    const sectionContent = this.content.properties[sectionId];

    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [sectionId]: {
          ...sectionContent,
          mandatory: sectionContent.mandatory.filter((key) => key !== questionId),
          order: sectionContent.order.filter((key) => key !== questionId),
          properties: Object.fromEntries(
            Object.entries(sectionContent.properties).filter(([key]) => key !== questionId)
          ),
        },
      },
    };
  }

  @action.bound
  setQuestionAsMandatory(questionId: string, sectionId: string): void {
    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [sectionId]: {
          ...this.content.properties[sectionId],
          mandatory: [...this.content.properties[sectionId].mandatory, questionId],
        },
      },
    };
  }

  @action.bound
  setQuestionAsOptional(questionId: string, sectionId: string): void {
    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [sectionId]: {
          ...this.content.properties[sectionId],
          mandatory: this.content.properties[sectionId].mandatory.filter((key) => key !== questionId),
        },
      },
    };
  }

  @action.bound
  updateDescription(questionId: string, newDescription: string, sectionId: string): void {
    this.content.properties = {
      ...this.content.properties,
      [sectionId]: {
        ...this.content.properties[sectionId],
        properties: {
          ...this.content.properties[sectionId].properties,
          [questionId]: {
            ...this.content.properties[sectionId].properties[questionId],
            description: newDescription,
          },
        },
      },
    };
  }

  @action.bound
  reorder(source: number, destination: number, sectionId: string): void {
    const result = Array.from(this.content.properties[sectionId].order);
    const [removed] = result.splice(source, 1);
    result.splice(destination, 0, removed);

    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [sectionId]: {
          ...this.content.properties[sectionId],
          order: result,
        },
      },
    };
  }

  @action.bound
  reorderBetweenSections(
    source: number,
    destination: number,
    sourceSectionId: string,
    destinationSectionId: string
  ): void {
    const sourceSectionOrder = this.content.properties[sourceSectionId].order;
    const sourceSectionMandatory = this.content.properties[sourceSectionId].mandatory;
    const destinationSectionOrder = this.content.properties[destinationSectionId].order;
    const destinationSectionMandatory = this.content.properties[destinationSectionId].mandatory;
    const [removed] = sourceSectionOrder.splice(source, 1);

    const newQuestionId = this.getNewQuestionId(destinationSectionOrder);

    const newId = `${destinationSectionId}::${newQuestionId}`;

    destinationSectionOrder.splice(destination, 0, newId);

    const index = sourceSectionMandatory.indexOf(removed);
    if (index !== -1) {
      // If it is, remove it from sourceSectionMandatory
      sourceSectionMandatory.splice(index, 1);
      // And add the newId to destinationSectionMandatory
      destinationSectionMandatory.push(newId);
    }

    this.content = {
      ...this.content,
      properties: {
        ...this.content.properties,
        [sourceSectionId]: {
          ...this.content.properties[sourceSectionId],
          order: sourceSectionOrder,
          mandatory: sourceSectionMandatory,
        },
        [destinationSectionId]: {
          ...this.content.properties[destinationSectionId],
          order: destinationSectionOrder,
          mandatory: destinationSectionMandatory,
          properties: {
            ...this.content.properties[destinationSectionId].properties,
            [newId]: {
              ...this.content.properties[sourceSectionId].properties[removed],
            },
          },
        },
      },
    };

    this.removeQuestion(removed, sourceSectionId);
  }

  @action.bound
  removeSection(sectionId: string, withQuestion: boolean): void {
    const sectionsOrder = this.content.order;
    sectionsOrder.splice(this.content.order.indexOf(sectionId), 1);

    if (withQuestion) {
      this.content = {
        ...this.content,
        order: sectionsOrder,
        properties: {
          ...this.content.properties,
        },
      };

      delete this.content.properties[sectionId];
    } else {
      const sectionContent = this.content.properties[sectionId];
      const sectionZero = this.content.properties['0'];

      Object.keys(sectionContent.properties).map((prop) => {
        this.reorderBetweenSections(sectionContent.order.indexOf(prop), sectionZero.order.length, sectionId, '0');
      });

      delete this.content.properties[sectionId];
    }
  }

  @action
  reorderSections(source: number, destination: number): void {
    const result = this.content.order;
    const [removed] = result.splice(source, 1);
    result.splice(destination, 0, removed);

    this.content = {
      ...this.content,
      order: result,
    };
  }

  getSavingCondition(details: DetailsTemplate | undefined): boolean {
    if (!details) return false;
    if (details.name.length === 0) return true;
    let saveCreateDisabled = false;
    const hasSections = (details.content?.order || []).length > 1;

    if (hasSections) {
      Object.keys(details.content.properties || {}).forEach((sectionKey) => {
        const section = details.content.properties[sectionKey];

        // first section can be empty if we have questions in other sections
        if (sectionKey !== '0' && section && section.order.length === 0) {
          saveCreateDisabled = section.order.length === 0;
        }
      });
    } else {
      saveCreateDisabled = details.content.properties['0'].order.length === 0;
    }
    return saveCreateDisabled;
  }
}
