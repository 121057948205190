import OutlinedInput from '@mui/material/OutlinedInput';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const ColumnMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  padding: 16px;

  margin-top: 45px;

  background-color: rgb(255, 255, 255);
  min-width: 300px;
  max-height: 450px;
  display: flex;

  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-right: -16px;
  margin-left: -16px;
  margin-top: 12px;
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 9px;
  padding: 0;
  padding-right: 4px;
`;
