import { action, observable, runInAction } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import TableStore from 'vatix-ui/lib/utils/stores/TableStore';

import { apiErrors, isBadRequest } from 'utils/api/errors';
import { EventType } from 'utils/events/constants';
import { ActionEvent, DeletedUsersPayload } from 'utils/events/types';
import API from 'utils/api';
import Logger from 'utils/logger';
import { EditUserResponse, TagResponse, UserResponse } from 'utils/api/types';

import RootStore from '../Root';

export default class UserListStore extends TableStore<RootStore, typeof API, UserResponse, UserResponse> {
  @observable tags: TagResponse[] = [];

  @observable selectedUsers: string[] = [];

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, api.loadUsers);
    this.sortingOrder = 'asc';
    this.sortingColumn = 'name';
  }

  @subscribe(EventType.LoggedIn)
  @action
  async loadUserTags(): Promise<void> {
    try {
      const { data } = await this.api.searchUserTags({})();

      runInAction(() => {
        this.tags = data.sort((prevTag, tag) => {
          const prevTagName = prevTag.name.toLowerCase();
          const tagName = tag.name.toLowerCase();

          if (prevTagName < tagName) {
            return -1;
          }
          if (prevTagName > tagName) {
            return 1;
          }
          return 0;
        });
      });
    } catch (e) {
      // @ts-ignore
      if (!isBadRequest(e)) {
        Logger.error('Invalid search user tags API response', e);
      }
      // @ts-ignore
      const errors = apiErrors(e, 'User tags cannot be loaded right now');

      errors.forEach((error: string) => {
        this.store.notification.enqueueErrorSnackbar(error);
      });
    }
  }

  @action.bound
  updateSelectedUsers(selected: string[]): void {
    this.selectedUsers = selected;
  }

  @subscribe(EventType.DeletedUsers)
  @action
  deletedUsers({ payload }: ActionEvent<DeletedUsersPayload>): void {
    if (this.isFailure || !this.isLoaded) {
      return;
    }

    this.items = this.items.filter((user) => {
      if (payload.users.indexOf(user.uuid) === -1) {
        return true;
      }
      eventBus.unregister(user);
      return false;
    });

    if (this.count !== undefined) {
      this.count -= payload.users.length;
    }
  }

  @subscribe(EventType.UpdatedUser)
  @action
  updatedUser({ payload }: ActionEvent<EditUserResponse>): void {
    if (this.isFailure || !this.isLoaded) {
      return;
    }

    const index = this.items.findIndex((u) => u.uuid === payload.uuid);
    if (index !== -1) {
      this.items[index] = {
        ...this.items[index],
        ...payload,
      };
    }
  }

  @action.bound
  resetUsers(): void {
    this.items.forEach((user) => eventBus.unregister(user));
    this.items = [];
  }
}
